export default class FeatureFlagKeys {
  static readonly DISABLE_SYSTEM_STATUS_CHECK = 'disable-system-status-check';

  static readonly IGNORE_PREVENT_INSTRUMENT_UPLOADS = 'ignore-prevent-instrument-uploads';

  static readonly INTEGRATION_TEST_ID = 'integration-test-id';

  static readonly TRACKING_ENV = 'trackingEnv';

  static readonly PLUGIN_MANIFEST_OVERRIDE_URL = 'plugin-manifest-override-url';

  static readonly MULTI_SELECTION = 'multi-selection';

  static readonly DEVELOPER = 'developer';

  static readonly DOWNLOAD_FILE = 'download-file';

  static readonly FILE_HISTORY = 'file-history';

  static readonly SUPPORT_NEXT = 'support-next';

  static readonly OKTA_SSO = 'okta-sso';

  static readonly NEW_AUTOSUGGEST = 'new-autosuggest';

  static readonly MAINTENANCE_MESSAGE = 'maintenance-message';

  static readonly CONVENTIONAL_PCR_MICRO_FRONTEND = 'conventional-pcr-micro-frontend';

  static readonly FLEET_MANAGEMENT = 'fleet-management';

  static readonly BRIO_API = 'brio-api';

  static readonly SETTINGS_PAGE = 'settings-page';

  static readonly PROJECT_REVIEWER = 'project-reviewer';

  static _cloudKeys: Array<string> = [
    FeatureFlagKeys.DOWNLOAD_FILE,
    FeatureFlagKeys.FILE_HISTORY,
    FeatureFlagKeys.MULTI_SELECTION,
    FeatureFlagKeys.DEVELOPER,
    FeatureFlagKeys.NEW_AUTOSUGGEST,
    FeatureFlagKeys.MAINTENANCE_MESSAGE,
    FeatureFlagKeys.CONVENTIONAL_PCR_MICRO_FRONTEND,
    FeatureFlagKeys.FLEET_MANAGEMENT,
    FeatureFlagKeys.BRIO_API,
    FeatureFlagKeys.SETTINGS_PAGE,
    FeatureFlagKeys.PROJECT_REVIEWER
  ];

  static _localKeys: Array<string> = [
    FeatureFlagKeys.DISABLE_SYSTEM_STATUS_CHECK,
    FeatureFlagKeys.INTEGRATION_TEST_ID,
    FeatureFlagKeys.TRACKING_ENV,
    FeatureFlagKeys.IGNORE_PREVENT_INSTRUMENT_UPLOADS,
    FeatureFlagKeys.SUPPORT_NEXT,
    FeatureFlagKeys.OKTA_SSO,
    FeatureFlagKeys.PLUGIN_MANIFEST_OVERRIDE_URL
  ];

  // eslint-disable-next-line no-underscore-dangle
  static _keys: Array<string> = [...FeatureFlagKeys._cloudKeys, ...FeatureFlagKeys._localKeys];

  // eslint-disable-next-line no-underscore-dangle
  static isValid = (key: string): boolean => FeatureFlagKeys._keys.includes(key);

  // eslint-disable-next-line no-underscore-dangle
  static isLocalOnly = (key: string): boolean => FeatureFlagKeys._localKeys.includes(key);
}
